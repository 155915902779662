.carousel-div {
  position: absolute !important;
  width: 80% !important;
  top: 80%;
  height: 300px;
  left: 50%;
  margin-left: -40%;
  @media (max-width: 576px) {
    display: none;
  }
}

.featured {
  align-self: center;
}
.stack-content {
  list-style: none;
  padding: 0;
}
.stack-content li {
  width: 300px;
  border-radius: 4px;
  display: flex;
}

/* STACKED CARDS */
.stacked-cards {
  position: relative;
}
.stacked-cards ul {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 0;
}

.stacked-cards li {
  cursor: pointer;
  position: absolute;
}

.stacked-cards li img {
  border-radius: 10px;
}
.stacked-cards li.pic1 {
  width: 62% !important;
  left: 35%;
  top: 30px;
  height: 280px;
  @media (max-width: 576px) {
    display: none;
  }
}
.stacked-cards li.pic2 {
  width: 52% !important;
  left: 30%;
  top: 15px;
  height: 320px;
  @media (max-width: 576px) {
    display: none;
  }
}
.stacked-cards li.pic3 {
  width: 62% !important;
  left: 0%;
  top: 30px;
  height: 280px;
  @media (max-width: 576px) {
    display: none;
  }
}
.stacked-cards li.pic4 {
  width: 52% !important;
  left: 15%;
  top: 15px;
  height: 320px;
  @media (max-width: 576px) {
    display: none;
  }
}
.stacked-cards li.pic5 {
  width: 35% !important;
  height: 350px;
  left: 32%;
  @media (max-width: 576px) {
    display: none;
  }
  // @media (max-width: 576px) {
  //   width: 100% !important;
  //   left: 0;
  //   height: 280px;
  // }
}
