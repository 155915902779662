.hero {
  min-height: 100vh;
  background-color: black;
  margin-left: auto !important;
  margin-right: auto !important;
}
.myNav {
  @media screen and (min-width: 500px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.hero-bg {
  background-image: url('../images/bg.png');
  background-color: #000000;
  height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.download-btn {
  width: 200px;
  height: 60px;
  border: none;
  border-radius: 30px;
  background-color: #e5da2f;
  font-weight: bold;
  font-size: 1.2rem;
}

.mockup-section {
  background-color: #fdde60;
  // padding-top: 120px;
  @media (max-width: 576px) {
    padding-top: 50px;
  }
}
.mock-two {
  background-image: url('../images/phone.png');
  background-color: #fdde60;
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dcard {
  width: 250px;
  border-radius: 10px;
}