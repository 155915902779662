.logo {
    height: 10%;
}

.header {
    background-color: #222222;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
} 
