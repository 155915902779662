.albumCard {
  max-width: 400px !important;
}
.img-div {
  position: relative;
}

.img-div .album-img {
  border-radius: 5px;
}

.img-div .img-content {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
}

.gray-border {
  background-color: #b3b3b3;
  // width: 85%;
  width: calc(100% - 48px);
  height: 8px;
  border-radius: 25px;
  position: absolute;
  left: 24px;
  top: -10px;
}

.dark-border {
  background-color: gray;
  // width: 88%;
  width: calc(100% - 38px);
  height: 8px;
  border-radius: 25px;
  position: absolute;
  left: 19px;
  top: -5px;
}

.centered-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

@media (max-width: 992px) {
  .gray-border {
    width: calc(100% - 48px);
  }
  .dark-border {
    width: calc(100% - 38px);
  }
}

@media (max-width: 768px) {
  .album-card .col-md-3 {
    margin-top: 30px;
  }
  .album-card {
    padding: 0 20px;
  }
  .img-div .album-img {
    height: 250px;
  }
  .gray-border {
    width: calc(100% - 48px);
  }
  .dark-border {
    width: calc(100% - 38px);
  }
}

@media (max-width: 576px) {
  .album-card .col-md-3 {
    margin-top: 30px;
  }
  .album-card {
    padding: 0 20px;
  }
  .gray-border {
    width: calc(100% - 48px);
  }
  .dark-border {
    width: calc(100% - 38px);
  }
  .img-div .album-img {
    height: 230px;
  }
}
