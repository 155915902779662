@import './variables';
@import '~bootstrap/scss/bootstrap.scss';
@import 'App.scss';
@import 'Home.scss';
@import './AlbumCards.scss';
@import './CardsCarousel.scss';

html,
body {
  font-family: 'Signika', sans-serif;
}

h1 {
  font-size: 3.5rem;
  font-weight: bold;
}

p,
a {
  font-size: 1.3rem;
  &:hover {
    text-decoration: none;
  }
}

.social-icon {
  font-size: 1rem !important;
  &:hover {
    text-decoration: none;
  }
}

.formInput {
  background-color: #585858 !important;
  border: 1.3px solid $lightSecondary;
  font-size: large;
  color: white !important;
  &:focus {
    border: 1.5px solid $lightSecondary;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
  &::placeholder {
    color: white !important;
    font-size: medium;
  }
}

html {
  scroll-behavior: smooth;
}

.policy-p {
  font-size: 1.1rem !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #3a3a3a;
}

::-webkit-scrollbar-thumb {
  background: #e5da2f;
}

::-webkit-scrollbar-thumb:hover {
  background: #e5d92fc7;
}

@media (min-width: 577px) {
  .mobile-only {
    display: none;
  }
}