$primary: #E5DA2F;
$secondary: #232323;
$lightSecondary: #585858;
$dark: #000000;
$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1600px
);
// $btn-border-radius: 4px;
// $btn-padding-y: 10px;
// $font-size-base: 1.1rem;
